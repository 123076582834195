// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overflowloader, .circle-square {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.overflowloader {
    z-index: 9998;
    width: 100vw;
    height: 100vh;
    background-color: rgba(245,245,245,0.4);
}
.circle-square {
    z-index: 9999;
    width: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    animation: circle-rotate 1.5s infinite linear;
}
.red,.green,.yellow,.blue{
    position: relative;
    width: 18px;
    height:18px;
    border-radius: 30px;
    margin: 5px;
}
.red {
    background-color:#AAC0CE;
}
.blue {
    background-color:#265472;
}
.green {
    background-color:#F19357;
}
.yellow {
    background-color:#F0B285;
}
/* CSS ANIMATION */
@keyframes circle-rotate {
    from {
        transform: translate(-50%, -50%);
    }
    to{
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
.loading{
 /* font-size: 23px;
  color: #FFF;
  text-align: center; */
font-size: 23px;
  font-style: oblique;
  color: #FFF;
  text-align: center;
  margin-top: 290px;
 vertical-align: middle;

}`, "",{"version":3,"sources":["webpack://./src/Components/Common.WebAppMate/fourdoted.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC;AACA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,uCAAuC;AAC3C;AACA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,6CAA6C;AACjD;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,wBAAwB;AAC5B;AACA,kBAAkB;AAClB;IACI;QACI,gCAAgC;IACpC;IACA;QACI,+CAA+C;IACnD;AACJ;AACA;CACC;;uBAEsB;AACvB,eAAe;EACb,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,iBAAiB;CAClB,sBAAsB;;AAEvB","sourcesContent":[".overflowloader, .circle-square {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n.overflowloader {\n    z-index: 9998;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(245,245,245,0.4);\n}\n.circle-square {\n    z-index: 9999;\n    width: 80px;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    animation: circle-rotate 1.5s infinite linear;\n}\n.red,.green,.yellow,.blue{\n    position: relative;\n    width: 18px;\n    height:18px;\n    border-radius: 30px;\n    margin: 5px;\n}\n.red {\n    background-color:#AAC0CE;\n}\n.blue {\n    background-color:#265472;\n}\n.green {\n    background-color:#F19357;\n}\n.yellow {\n    background-color:#F0B285;\n}\n/* CSS ANIMATION */\n@keyframes circle-rotate {\n    from {\n        transform: translate(-50%, -50%);\n    }\n    to{\n        transform: translate(-50%, -50%) rotate(360deg);\n    }\n}\n.loading{\n /* font-size: 23px;\n  color: #FFF;\n  text-align: center; */\nfont-size: 23px;\n  font-style: oblique;\n  color: #FFF;\n  text-align: center;\n  margin-top: 290px;\n vertical-align: middle;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/@syncfusion/ej2-base/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/@syncfusion/ej2-popups/styles/material.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/Fonts/effra_trial_rg-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/Fonts/effra_trial_rg-webfont.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css"; */

@font-face {
  font-family: 'effra_trialregular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
  font-weight: normal;
  font-style: normal;

}

.Toastify__toast-body {
  font-size: 17px!important;
  font-weight:800!important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAIA,mFAAmF;;AAEnF;EACE,iCAAiC;EACjC;0DACkE;EAClE,mBAAmB;EACnB,kBAAkB;;AAEpB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B","sourcesContent":["@import \"../node_modules/@syncfusion/ej2-base/styles/material.css\";\n@import \"../node_modules/@syncfusion/ej2-buttons/styles/material.css\";\n@import \"../node_modules/@syncfusion/ej2-inputs/styles/material.css\";\n@import \"../node_modules/@syncfusion/ej2-popups/styles/material.css\";\n/* @import \"../node_modules/@syncfusion/ej2-react-calendars/styles/material.css\"; */\n\n@font-face {\n  font-family: 'effra_trialregular';\n  src: url('./assets/Fonts/effra_trial_rg-webfont.woff2') format('woff2'),\n    url('./assets/Fonts/effra_trial_rg-webfont.woff') format('woff');\n  font-weight: normal;\n  font-style: normal;\n\n}\n\n.Toastify__toast-body {\n  font-size: 17px!important;\n  font-weight:800!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

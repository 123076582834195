import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import "./Shareinspiration.css";
import SharePostpopup from "./SharePostpopup";
import InviteFriendpopup from "../Common.WebAppMate/InviteFriendpopup";
import Head from "../Layout.WebAppMate/head/Head"
import { makeGetRequest, makePostRequest, makePostRequestForAll } from "../../services/api";
import { URLS } from "../../constants";
import ResourceCard from "./ResourceCard";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { decodeToken } from "../../utilities/CustomFunctions";
import { imageUrl } from "../Common.WebAppMate/FullDescription";
import Multiselect from "multiselect-react-dropdown";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import CryptoJS from 'crypto-js';
import { useNavigate } from "react-router-dom";
import { Learningpillar } from "../Common.WebAppMate/Learningpillar";
const secretKey = process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY;
/**
 * create components is used for show share resource
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */
function Shareinspiration() {
  const naviget = useNavigate()
  let getimage = imageUrl;
  const [BookMentor, setBookMentor] = useState(false);
  const [RoportPopup, setReportPopup] = useState(false);
  const [spin, setSpin] = useState(false);
  const [data, setData] = useState([]);
  const [value, setValue] = React.useState("inspiration");
  const [page, setPage] = useState(1);
  const [myFriend, setMyFriend] = useState([]);
  const [getfile, setgetfile] = useState("");
  const [friendId, setFriendId] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupId, setGroupId] = useState([]);
  const [rightSideData, setRightSideData] = useState([]);
  const [downSpin, setDownSpin] = useState(false);
  const [status, setStatus] = useState(false);
  const [remainingCount, setRemainingCount] = useState(1)
  const [isMounted, setIsMounted] = useState(false);
  let userData = decodeToken().userAuth;
  let fullName = userData?.firstName + " " + userData?.lastName;

  // Set by default selected value all for frnds and group tab
  useEffect(() => {
    if (value === "friend") {
      let arr = []
      arr.push("all")
      setFriendId(arr)
    }
    if (value === "following") {
      let arr = []
      arr.push("all")
      setGroupId(arr)
    }

  }, [value])

  useEffect(() => {
    userData?.photo && setgetfile(getimage + userData.photo)
  }, [userData, getimage])
  // get the list of my friend
  const MyFriendList = () => {
    makeGetRequest(URLS.myFriendListForShare, null, null)
      .then((res) => {
        let arr = [{ name: "All", value: "all" }];
        res?.data?.map((val) => arr.push({ value: val.id, name: val.name }));
        setMyFriend(arr);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // get the list of my group
  const myGroups = () => {
    makePostRequest(URLS.getGroupList, null, {}, null)
      .then((res) => {
        const arr = [
          { name: "All", value: "all" }
        ]
        res?.data?.myGroups?.data?.map((val, index) => {
          return arr.push({ name: val?.group_name, value: val?.id })
        })
        setGroupList(arr);
      }).catch((error) => {
        console.log("error:::", error)
      })

  }

  //Deselect previous selected values on select of all
  const handleGroupDropdown = (e) => {
    let arr = [];
    e.length > 0 &&
      e.map((val, i) => arr.push(val.value));
    if ((arr.includes("all") && arr[arr.length - 1] === "all" && arr.length > 1) || (arr.length === 1 && arr.includes("all"))) {
      setGroupId(["all"])
    } else {
      const a = [];
      e.forEach((item) => {
        if (item.value !== "all") {
          a.push(item.value);
        }
      });
      setGroupId(a);
    }

  }

  //Deselect previous selected values on select of all
  const handlefrndsDropdown = (e) => {
    let arr = [];
    e.length > 0 &&
      e.map((val, i) => arr.push(val.value));
    if ((arr.includes("all") && arr[arr.length - 1] === "all" && arr.length > 1) || (arr.length === 1 && arr.includes("all"))) {
      setFriendId(["all"])
    } else {
      const a = [];
      e.forEach((item) => {
        if (item.value !== "all") {
          a.push(item.value);
        }
      });
      setFriendId(a);
    }
  }

  // this function use for get resources
  const getData = () => {
    // shared_by: all, friend, following, local_resource_services, local_experience
    if (page === 1) {
      setSpin(true);
    }
    else if (status) {
      setDownSpin(true)
    }
    const payLoad = {
      shared_by: value,
      friends: friendId.includes("all") ? [] : friendId,
      groups: groupId.includes("all") ? [] : groupId,
      page_number: page,
    };
    console.log("payLoad", payLoad)
    makePostRequestForAll(URLS.getSharedResources, payLoad)
      .then((res) => {
        console.log("res::::::", res)
        setSpin(false);
        if (res?.data?.response[0] !== undefined||remainingCount!=0) {
          const save = [];
          const dump = [];
          res?.data?.save?.map((val) => {
            return save.push(val.resource_id)
          })
          let arr = []
          if (page === 1) {
            arr = [...res?.data?.response]
          } else {
            arr = [...data, ...res?.data?.response]
          }
          for (var i = 0; i < arr.length; i++) {
            if (save.includes(arr[i].resource_id)) {
              const updatedObject = {
                ...arr[i],
                save: "yes"
              };
              dump.push(updatedObject)
            } else {
              const updatedObject = {
                ...arr[i],
                save: "no"
              };
              dump.push(updatedObject)
            }
          }
          console.log("value",value)
          if ((value === "friend" || value === "following" || value === "local_resource_services") ) {
            const data = {
              "item_per_page": 1,
              "page_number": page,
              "data_type": value === "following" ? "group" : value,
              "group_id": groupId.includes("all") ? [] : groupId,
              "friend_id": friendId.includes("all") ? [] : friendId
            }
            console.log("payload group",data)
            makePostRequest(URLS.shareInvitationGroup, null, data, null)
              .then((res) => {
                console.log("invite",res.data)
                setRemainingCount(res?.data?.remaining_count)
                const dummy = [...dump, ...res?.data?.groupShare]
                const sorted = dummy.sort((a, b) => {
                  const dateA = new Date(a.shared_date_time);
                  const dateB = new Date(b.shared_date_time);
                  return dateB - dateA;
                });
                setData(sorted);
                setSpin(false);
                setDownSpin(false)
              })
          } else {
            setData(dump);
            setSpin(false);
            setDownSpin(false)
          }
        }
        else {
          page === 1 && setData([])
          console.log("checking")
        }
        if (res?.data?.remaining_count === 1 || res?.data?.remaining_count === 1) {
          setStatus(true)
        } else {
          setStatus(false)
        }
      })
      .catch((e) => {
        console.log(e);
        setSpin(false);
      });
  };

  useEffect(() => {
    isMounted && getData();
    // eslint-disable-next-line
  }, [value, page, isMounted]);

  // this function use for side Image
  const getAllMasterImage = () => {
    makeGetRequest(URLS.getAllMasterImage, null, null, null)
      .then((res) => {
        setRightSideData(res.data)
      }).catch((error) => {
        console.log("error::", error)
      })
  }
  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  // these function are use for get the data on load
  const handleScroll = useCallback(() => {
    if (window.innerHeight + Math.round(document.documentElement.scrollTop) === document.documentElement.offsetHeight) {
      setPage((prevPage) => prevPage + 1);
    }
  }, []);

  useEffect(() => {
    if (isMounted) {
      MyFriendList();
      myGroups();
      getAllMasterImage();
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMounted, handleScroll]);

  useEffect(() => {

    window.scrollTo({ top: 0, behavior: "smooth" });
    document.body.classList.add("shareinspiration");
    return () => {
      document.body.classList.remove("shareinspiration");
    };
  }, []);



  return (
    <>
      {BookMentor === true ? (
        <SharePostpopup
          data={() => setBookMentor(!BookMentor)}
          userImg={getfile}
          fullName={fullName}
        />
      ) : null}
      {RoportPopup === true ? (
        <InviteFriendpopup datar={() => setReportPopup(!RoportPopup)} />
      ) : null}
      {spin && (
        <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div>
      )}
      <Head title="Share"
        content="Share to Community: Ask questions, Start a chat, post an event or resource, or send encouragement to your Friends, Groups, or Local MatchED members
      Shared with Me: Stay current with a list of recently shared resources and  events from your Friends, Groups, and Local MatchED Members
      Local Events: Stay updated on local events and gatherings in your community posted by other MatchED members"
        name="Share to MatchED Community" ></Head>
      <div className="col-lg-7">
        <div className="content-inspiration">
          <div className="header-div">
            <h1 className="sharewithheading">Share with others:</h1>
          </div>
          <div className="header-down-div">
            <div className="down-div">
              <img className="img-fluid" src={getfile} alt="MatchED user" />
              <div className="sharewantsComunity" onClick={() => setBookMentor(!BookMentor)}> What do you want to share with the community? </div>
            </div>
          </div>
          <div className="header-shared">
            <h3>
              Shared with me:
            </h3>
          </div>
          <div className="inspiration-container">
            <div className="inspiration-inner-content">
              <div className="d-md-block d-none">
                <Paper square>
                  <Tabs
                    value={value}
                    textColor="primary"
                    indicatorColor="primary"
                    onChange={(event, newValue) => {
                      setValue(newValue);
                      setPage(1);
                      setData([]);
                      setStatus(false);
                      setGroupId([]);
                      setFriendId([])
                    }}
                  >
                    <Tab label="Inspirations" value="inspiration" />
                    <Tab label="Friends" value="friend" />
                    <Tab label="Groups" value="following" />
                    <Tab
                      label="Local Resources & Services"
                      value="local_resource_services"
                    />
                    <Tab
                      label="Local Experiences"
                      value="local_experience"
                    />
                  </Tabs>
                </Paper>
              </div>
              <div className="row">
                <div className="col-md-12 d-md-none d-sm-block">
                  <div class="form-group">
                    <select class="form-control" id="exampleFormControlSelect1" onChange={(e) => {
                      setValue(e.target.value);
                      setPage(1);
                      setData([]);
                      setStatus(false)
                    }} value={value}>
                      <option value="inspiration">Inspirations</option>
                      <option value="friend">Friends</option>
                      <option value="following">Groups</option>
                      <option value="local_resource_services" >Local Resources & Services</option>
                      <option value="local_experience">Local Experiences</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="mt-2 inspireInnerBox">
                {
                  value === "friend" && (
                    <>
                      <div className=" friendsFild border-bottom-inspire my-1">
                        <div className="text">Refine search results</div>
                        <div className="friendSelect">
                          <div className={`share-multiple-form-select ${(myFriend && friendId.length > 1) ? "showOneSelect" : "showMultipleSelect"}`}>
                            {myFriend && (
                              <Multiselect
                                options={myFriend}
                                onRemove={handlefrndsDropdown}
                                onSelect={handlefrndsDropdown}
                                showCheckbox
                                displayValue="name"
                                placeholder="Friends"
                                showArrow={true}
                                selectedValues={myFriend.filter(item => {
                                  return friendId.includes(item.value)
                                })}
                              />
                            )}
                          </div>
                          <button
                            onClick={() => {
                              setPage(1);
                              getData();
                            }}
                          >
                            <i className="fa fa-search iconsearch" />
                          </button>
                        </div>
                      </div>
                    </>
                  )
                }
                {
                  value === "following" && (
                    <>
                      <div className=" friendsFild border-bottom-inspire my-1">
                        <div className="text">Refine search results</div>
                        <div className="friendSelect">
                          <div className={`share-multiple-form-select ${(groupList && groupId.length > 1) ? "showOneSelect" : "showMultipleSelect"}`}>
                            {groupList && (
                              <>
                                <Multiselect
                                  options={groupList}
                                  onRemove={handleGroupDropdown}
                                  onSelect={handleGroupDropdown}
                                  showCheckbox
                                  displayValue="name"
                                  placeholder="My group"
                                  showArrow={true}
                                  selectedValues={groupList.filter(item => {
                                    return groupId.includes(item.value)
                                  })}

                                />
                              </>
                            )}
                          </div>
                          <button
                            onClick={() => {
                              setPage(1);
                              getData();
                            }}
                          >
                            <i className="fa fa-search iconsearch" />
                          </button>
                        </div>
                      </div>
                    </>
                  )
                }
                {data?.map((val) => {
                  return (
                    <>
                      {
                        val.resource_id ? <ResourceCard value={val} userImg={getfile} /> :

                          <div className="inspiration-div">
                            <div className="inspiration-inner-div">
                              <div className="d-flex gap-3">
                                <img className="img-fluid" src={val?.group_shared_by_user?.photo} alt="matched user" />
                                <h3 className="text_capital"><strong className="friendInspirationcapital">{val?.group_shared_by_user?.name}</strong> shared the group : <br />
                                </h3>
                                <label></label>
                              </div>

                            </div>
                            <div className="SummerCamp">
                              <div className="d-flex">
                                {
                                  <div className="img-Facilitiess" style={{ cursor: "pointer" }}>
                                    <img src={val?.group_detail?.image} alt="MatchED group name" className="curriculum-image2" onClick={() => naviget(`/groupjoin/${btoa(CryptoJS.AES.encrypt(val.group_id.toString(), secretKey).toString())}`)} />
                                  </div>
                                }
                                <div className="summer-text">
                                  <h3 className="share-title" onClick={() => naviget(`/groupjoin/${btoa(CryptoJS.AES.encrypt(val.group_id.toString(), secretKey).toString())}`)}>{val?.group_detail?.group_name}</h3>
                                  <div className=" description-sharee" dangerouslySetInnerHTML={{ __html: val?.group_detail?.about_group }}>
                                  </div>
                                  <div className="text-curriculum11 mt-2">
                                    {Array.isArray(val?.group_detail?.tbl_group_learning_pillars) &&
                                      val?.group_detail?.tbl_group_learning_pillars?.map((item, i) => Learningpillar(item?.tbl_learning_pillar_master?.name, i))}
                                  </div>
                                  <div>
                                    {val?.isJoin === false ? "" :
                                      <span className="reviews" onClick={() => naviget(`/groupjoin/${btoa(CryptoJS.AES.encrypt(val.group_id.toString(), secretKey).toString())}`)}>Join Group</span>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      }
                    </>
                  );
                })}
              </div>
              {
                downSpin && <div className="share-spiner">
                  <FourDotsLoader />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-3">
        <div className="inspiration-div1">
          {rightSideData && rightSideData.map((val, index) => {
            return (
              <>
                {
                  val.image_type === value && <div className="inspiration-inner-div1" key={index}>
                    <div className="sharesidebarimgBox">
                      <img className="img-fluid" src={val?.image} alt="matchEd user" />
                    </div>
                    <h3 className="text-capitalize">
                      {val?.short_description}
                    </h3>
                  </div>
                }
              </>
            )
          })}
          <div className="button-div">
            <button onClick={() => setReportPopup(!RoportPopup)}>Invite a Friend</button>
          </div>
        </div>
      </div>
    </>
  );
}
export default Shareinspiration;

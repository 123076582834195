// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textname1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    padding: 0px 0px 0px 6px;
    width: 156px;
}
.success-msg{
    padding : 20px;
    background-color: aquamarine;
    border-radius: 10px;
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Subscription.WebAppMate/SubscriptionVerify.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;IACd,wBAAwB;IACxB,YAAY;AAChB;AACA;IACI,cAAc;IACd,4BAA4B;IAC5B,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".textname1 {\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 20px;\n    letter-spacing: 0.01em;\n    color: #2D3134;\n    padding: 0px 0px 0px 6px;\n    width: 156px;\n}\n.success-msg{\n    padding : 20px;\n    background-color: aquamarine;\n    border-radius: 10px;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

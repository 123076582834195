import { AsYouType, parsePhoneNumber } from 'libphonenumber-js'

export const commonService = {

  formatPhoneNumebr,
  validatePhoneNumebr,
  //validatePhoneNumebrLogin,
};

export function formatSize(sizeInKB) {
  const thresholdInKB = 1024;
  const thresholdInMB = 1024;

  if (sizeInKB < thresholdInKB) {
    return `${sizeInKB.toFixed(2)} KB`;
  } else if (sizeInKB < thresholdInMB * thresholdInKB) {
    const sizeInMB = sizeInKB / thresholdInKB;
    return `${sizeInMB.toFixed(2)} MB`;
  } else {
    const sizeInGB = sizeInKB / (thresholdInMB * thresholdInKB);
    return `${sizeInGB.toFixed(2)} GB`;
  }
}

function formatPhoneNumebr(phone) {
  if (!phone) return '';
  phone = phone.toString();
  if (phone.includes('(') && !phone.includes(')')) {
    return phone.replace('(', '');
  }

  return new AsYouType('US').input(phone)
}

function validatePhoneNumebr(phone) {
  var params = "";
  if (phone !== '') {
    const phoneNumber = parsePhoneNumber('+1' + phone, 'US')
    if (phoneNumber.isValid()) {
      params = {
        msg: "Valid phone number",
        status: true,
        formattedNumber: phoneNumber.formatNational()
      };
    } else {
      params = {
        msg: "Invalid phone number for US",
        status: false,
        formattedNumber: phoneNumber.formatNational()
      };
    }
  } else {
    params = {
      msg: "Phone number is required.",
      status: false,
      formattedNumber: ''
    };
  }
  return params;
}

export function toSeoUrl(url) {
  return url.toString()               // Convert to string
    .normalize('NFD')               // Change diacritics
    .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
    .replace(/\s+/g, '-')            // Change whitespace to dashes
    .toLowerCase()                  // Change to lowercase
    .replace(/&/g, '-and-')          // Replace ampersand
    .replace(/[^a-z0-9-]/g, '')     // Remove anything that is not a letter, number, or dash
    .replace(/-+/g, '-')             // Remove duplicate dashes
    .replace(/^-*/, '')              // Remove starting dashes
    .replace(/-*$/, '');             // Remove trailing dashes
}
export const options = {
  //  componentRestrictions: { country: ["US", "IND", "CA", "BR"] },
  fields: ["address_components", "geometry", "icon", "name"],
  //strictBounds: false,
  types: [],
};

export function renderDescription(description) {
  if (!description || description === undefined) return null; // Handle empty description
  let data
  if (!description.startsWith('<') || !description.endsWith('>')) {
    data = '<p>' + description + '</p>';
  } else {
    data = description
  }
  const modifiedDescription = data
    .replace(/&amp;/g, '&') // Replaces &amp; with &
    .replace(/&rsquo;/g, '’') // Replaces &rsquo; with ’
    .replace(/&ntilde;/g, 'ñ') // Replaces &ntilde; with ñ
    .replace(/&iquest;/g, '¿') // Replaces &iquest; with ¿
    .replace(/&#151;/g, '—') // Replaces &#151; with —
    .replace(/&lt;/g, '<') // Replaces &lt; with <
    .replace(/&gt;/g, '>') // Replaces &gt; with >
    .replace(/&quot;/g, '"') // Replaces &quot; with "
    .replace(/&ldquo;/g, '“') // Replaces &ldquo; with “
    .replace(/&rdquo;/g, '”') // Replaces &rdquo; with ”
    .replace(/&ndash;/g, '–') // Replaces &ndash; with –
    .replace(/&mdash;/g, '—') // Replaces &mdash; with —
    .replace(/&reg;/g, '®') // Replaces &reg; with ®
    .replace(/&copy;/g, '©') // Replaces &copy; with ©
    .replace(/&trade;/g, '™') // Replaces &trade; with ™
    .replace(/&eacute;/g, 'é') // Replaces &eacute; with é
    .replace(/&aacute;/g, 'á') // Replaces &aacute; with á
    .replace(/&iacute;/g, 'í') // Replaces &iacute; with í
    .replace(/&oacute;/g, 'ó') // Replaces &oacute; with ó
    .replace(/&uacute;/g, 'ú'); // Replaces &uacute; with ú
  return <div dangerouslySetInnerHTML={{ __html: modifiedDescription }}></div>;
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-content {
   position:absolute;
    margin-left: 200px;
    margin-top: 20px;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 15px;
  }
.dropdown a:hover {background-color: #ddd;}
`, "",{"version":3,"sources":["webpack://./src/Components/ServeResources.WebAppMate/Cardcomponent1.css"],"names":[],"mappings":"AAAA;GACG,iBAAiB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;IACd,4CAA4C;IAC5C,UAAU;EACZ;EACA;IACE,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,cAAc;IACd,eAAe;EACjB;AACF,mBAAmB,sBAAsB,CAAC","sourcesContent":[".dropdown-content {\n   position:absolute;\n    margin-left: 200px;\n    margin-top: 20px;\n    background-color: #f1f1f1;\n    min-width: 160px;\n    overflow: auto;\n    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);\n    z-index: 1;\n  }\n  .dropdown-content a {\n    color: black;\n    padding: 12px 16px;\n    text-decoration: none;\n    display: block;\n    font-size: 15px;\n  }\n.dropdown a:hover {background-color: #ddd;}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

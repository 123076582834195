// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ourStory {
    padding: 50px 0 90px 0;
}

.storyInnerBox {
    text-align: left;
    padding: 25px 0 0 50px;
}
.storyInnerBox ul {
    max-height: 265px;
    overflow-y: auto;
    height: 100%;
    padding-left: 20px;
}
.storyInnerBox h4 {
    font-weight: 700;
    font-size: 45px;
    line-height: 54px;
    font-family: 'Inter';
    color: #2D3134;
    margin-bottom: 15px;
}
.storyInnerBox h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #AAC0CE;
}
.storyInnerBoxparainfo {
	 max-height: 270px;
    overflow-y: auto;
}
.storyInnerBox p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #5B5F62;
    font-family: 'Inter';
	margin-bottom:15px;
}

.storyInnerBox li {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5B5F62;
    font-family: 'Inter';
}
`, "",{"version":3,"sources":["webpack://./src/Components/HomePage.WebAppMate/story-css.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;AAC1B;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,oBAAoB;IACpB,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;EACE,iBAAiB;IACf,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,oBAAoB;CACvB,kBAAkB;AACnB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,oBAAoB;AACxB","sourcesContent":[".ourStory {\n    padding: 50px 0 90px 0;\n}\n\n.storyInnerBox {\n    text-align: left;\n    padding: 25px 0 0 50px;\n}\n.storyInnerBox ul {\n    max-height: 265px;\n    overflow-y: auto;\n    height: 100%;\n    padding-left: 20px;\n}\n.storyInnerBox h4 {\n    font-weight: 700;\n    font-size: 45px;\n    line-height: 54px;\n    font-family: 'Inter';\n    color: #2D3134;\n    margin-bottom: 15px;\n}\n.storyInnerBox h5 {\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 30px;\n    line-height: 36px;\n    color: #AAC0CE;\n}\n.storyInnerBoxparainfo {\n\t max-height: 270px;\n    overflow-y: auto;\n}\n.storyInnerBox p {\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 22px;\n    color: #5B5F62;\n    font-family: 'Inter';\n\tmargin-bottom:15px;\n}\n\n.storyInnerBox li {\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 24px;\n    color: #5B5F62;\n    font-family: 'Inter';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doc_div {
	padding-left: 5px;
    margin-bottom: 10px;
	padding-top:4px;
	height:auto;
}
.date-separator {
  text-align: center;
  color: #888;
  margin: 10px 0;
}
.middle-chat-part .chatManuiocns{
  position: absolute;
  top: 0;
  width: 95%;
}
.date-separator::before,
.date-separator::after {
  content: "";
  display: inline-block;
  width: 35%;
  margin-bottom: 4px;
  border-top: 1px solid #888;
}
.chat-content .chatList1 .sent{
  word-break: break-word;
}
div.chat-messages-textarea div.chat-messages-textarea-rt textarea.textareaautosize{
  font-size: 15px;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-height:90px;
  overflow-y: auto;
}
.middle-chat-part .chatautotextarea{
  position: relative;
  width: 100%;
  border: 1px solid #d9d9d9;
  right: 0px;
  border-top: none;
  }
  
  div.chatpopupauto{
    top:auto;
	bottom:5px;
  }
  
  .messages ul.chatlistautotext{
    height: 371px;
  }
  
  div.chat-messages-textarea div.chat-messages-textarea-rt .areabtnauto{
  top: auto;
  bottom: -22px;
  }
  div.chat-messages-textarea .chatmessageautotext{
    position: absolute;
      right: 0;
      bottom: 21px;
  }
  div.left-div .well-chat-icon + .modal-container {
    bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Chat.WebAppMate/Singlechat.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;IACd,mBAAmB;CACtB,eAAe;CACf,WAAW;AACZ;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,MAAM;EACN,UAAU;AACZ;AACA;;EAEE,WAAW;EACX,qBAAqB;EACrB,UAAU;EACV,kBAAkB;EAClB,0BAA0B;AAC5B;AACA;EACE,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,oBAAoB;EACpB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,yBAAyB;EACzB,UAAU;EACV,gBAAgB;EAChB;;EAEA;IACE,QAAQ;CACX,UAAU;EACT;;EAEA;IACE,aAAa;EACf;;EAEA;EACA,SAAS;EACT,aAAa;EACb;EACA;IACE,kBAAkB;MAChB,QAAQ;MACR,YAAY;EAChB;EACA;IACE,WAAW;AACf","sourcesContent":[".doc_div {\n\tpadding-left: 5px;\n    margin-bottom: 10px;\n\tpadding-top:4px;\n\theight:auto;\n}\n.date-separator {\n  text-align: center;\n  color: #888;\n  margin: 10px 0;\n}\n.middle-chat-part .chatManuiocns{\n  position: absolute;\n  top: 0;\n  width: 95%;\n}\n.date-separator::before,\n.date-separator::after {\n  content: \"\";\n  display: inline-block;\n  width: 35%;\n  margin-bottom: 4px;\n  border-top: 1px solid #888;\n}\n.chat-content .chatList1 .sent{\n  word-break: break-word;\n}\ndiv.chat-messages-textarea div.chat-messages-textarea-rt textarea.textareaautosize{\n  font-size: 15px;\n  border-radius: 8px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  max-height:90px;\n  overflow-y: auto;\n}\n.middle-chat-part .chatautotextarea{\n  position: relative;\n  width: 100%;\n  border: 1px solid #d9d9d9;\n  right: 0px;\n  border-top: none;\n  }\n  \n  div.chatpopupauto{\n    top:auto;\n\tbottom:5px;\n  }\n  \n  .messages ul.chatlistautotext{\n    height: 371px;\n  }\n  \n  div.chat-messages-textarea div.chat-messages-textarea-rt .areabtnauto{\n  top: auto;\n  bottom: -22px;\n  }\n  div.chat-messages-textarea .chatmessageautotext{\n    position: absolute;\n      right: 0;\n      bottom: 21px;\n  }\n  div.left-div .well-chat-icon + .modal-container {\n    bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

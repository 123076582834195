// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eligible-public-funding {
    min-height: 30px;
}
div.text-curriculum11 img {
   display: inline-block;
}
/* .slick-initialized .slick-slide {
   padding: 0 10px;
} */
.main-card-container {
    position: relative;
}
.explore-container .main-card-container button:nth-of-type(1),
.explore-container .main-card-container button:nth-of-type(2) {
	 position: absolute;
	top: 45%;
    border: none;width: auto;
    background: transparent;
	cursor:pointer;    z-index: 2;
}
.explore-container .main-card-container button.slick-arrow {
  display:none!important;
}
.explore-container .main-card-container button:nth-of-type(1) {
    left: -20px;
}
.explore-container .main-card-container button:nth-of-type(2) {
    right: -20px;
}

.homepage .card-container .text-curriculum11 span{
    margin-right: 5px;
}
.slick-initialized div.slick-slide {
    display: inline-block;
}
.slick-slider .card-container {
	box-shadow:none
}
`, "",{"version":3,"sources":["webpack://./src/Components/HomePage.WebAppMate/explore.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;GACG,qBAAqB;AACxB;AACA;;GAEG;AACH;IACI,kBAAkB;AACtB;AACA;;EAEE,kBAAkB;CACnB,QAAQ;IACL,YAAY,CAAC,WAAW;IACxB,uBAAuB;CAC1B,cAAc,KAAK,UAAU;AAC9B;AACA;EACE,sBAAsB;AACxB;AACA;IACI,WAAW;AACf;AACA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,qBAAqB;AACzB;AACA;CACC;AACD","sourcesContent":[".eligible-public-funding {\n    min-height: 30px;\n}\ndiv.text-curriculum11 img {\n   display: inline-block;\n}\n/* .slick-initialized .slick-slide {\n   padding: 0 10px;\n} */\n.main-card-container {\n    position: relative;\n}\n.explore-container .main-card-container button:nth-of-type(1),\n.explore-container .main-card-container button:nth-of-type(2) {\n\t position: absolute;\n\ttop: 45%;\n    border: none;width: auto;\n    background: transparent;\n\tcursor:pointer;    z-index: 2;\n}\n.explore-container .main-card-container button.slick-arrow {\n  display:none!important;\n}\n.explore-container .main-card-container button:nth-of-type(1) {\n    left: -20px;\n}\n.explore-container .main-card-container button:nth-of-type(2) {\n    right: -20px;\n}\n\n.homepage .card-container .text-curriculum11 span{\n    margin-right: 5px;\n}\n.slick-initialized div.slick-slide {\n    display: inline-block;\n}\n.slick-slider .card-container {\n\tbox-shadow:none\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

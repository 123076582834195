import { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import "./responsive.css";
import "./assets/Fonts/stylesheet.css";
import './Components/ViewProfile.WebAppMate/childprofileupdateview.css';
import Home from "./Components/HomePage.WebAppMate/Home";
import Signup from "./Components/Registration.WebAppMate/Signup";
import SignIn from "./Components/Registration.WebAppMate/SignIn";
import DeleteAccount from "./Components/Registration.WebAppMate/DeleteAccount";
import Parentregistrationform from "./Components/Registration.WebAppMate/Parentregistrationform";
import Childinfo from "./Components/Registration.WebAppMate/Childinfo";
import Serve from "./Components/ServeResources.WebAppMate/Serve";
import SavePage from "./Components/SavedResources.WebAppMate/Save_page";
import Shareinspiration from "./Components/ShareInspiration.WebAppMate/Shareinspiration";
import SubscriptionBuy from "./Components/Subscription.WebAppMate/SubscriptionBuy";
import PurchaseResource from "./Components/PurchaseResources.WebAppMate/PurchaseReasources";
import PurchasePagesfriend from "./Components/ShareInspiration.WebAppMate/FriendList";
import Purchasepagecard from "./Components/PurchaseResources.WebAppMate/Purchasepagecard";
import SubscriptionVerify from "./Components/Subscription.WebAppMate/SubscriptionVerify";
import BookASessionManualpicking from "./Components/BookaSession.WebAppMate/BookASessionManualpicking";
import ServerPaymentHistory from "./Components/PurchaseResources.WebAppMate/ServerPaymentHistory";
import SharePostpopup from "./Components/ShareInspiration.WebAppMate/SharePostpopup";
import ServeYourresources from "./Components/ServeResources.WebAppMate/Serve_yourresources";
import Childprofileupdateview from "./Components/ViewProfile.WebAppMate/Child_profile_page";
import ServeDashboard from "./Components/ServeResources.WebAppMate/ServeDashboard";
import Forgetpassword from "./Components/Registration.WebAppMate/Forgetpassword";
import Updatepass from "./Components/Registration.WebAppMate/Updatepass";
import Permissonsharepopup from "./Components/ShareInspiration.WebAppMate/Permissonsharepopup";
import SaveResourceList from "./Components/SavedResources.WebAppMate/SaveResourceList";
import { Routes, Route } from "react-router-dom";
import Subscription from "./Components/Subscription.WebAppMate/Subscription";
import { ToastContainer } from "react-toastify";
import Searchpage from "./Components/Search.WebAppMate/Searchpage";
import Reportpopup from "./Components/Search.WebAppMate/Reportpopup";
import InviteFriendpopup from "./Components/Common.WebAppMate/InviteFriendpopup";
import FullDescription from "./Components/Common.WebAppMate/FullDescription";
import Footer from "./Components/Layout.WebAppMate/Footer";
import Notification from "./Components/Layout.WebAppMate/Notification";
import NotificationSettings from "./Components/Layout.WebAppMate/NotificationSettings";
import PageNotFound from "./Components/Common.WebAppMate/PageNotFound";
import ProtectedRoute from "./services/ProtectedRoute";
import Creategroup from "./Components/CreateGroup.WebAppMate/Create-new-group";
import Yourgroup from "./Components/CreateGroup.WebAppMate/Your-Group";
import Yourgroups4 from "./Components/CreateGroup.WebAppMate/Yourgroups4";
import MySubscription from "./Components/Subscription.WebAppMate/MySubscription";
import GroupYouhaveJoin from "./Components/CreateGroup.WebAppMate/GroupYouhaveJoin";
import Createschedule from "./Components/BookaSession.WebAppMate/Createschedule";
import SaleHistory from "./Components/PurchaseResources.WebAppMate/SaleHistory";
import UserProfile from "./Components/ShareInspiration.WebAppMate/UserProfile";
import { Navigate ,useNavigate,useParams} from "react-router-dom";
import { decodeToken } from "./utilities/CustomFunctions";
import Sidebar from "./Components/Layout.WebAppMate/Sidebar";
import Header1 from "./Components/Layout.WebAppMate/LoginHeader";
import Header from "./Components/Layout.WebAppMate/Header";
import { useDispatch, useSelector } from "react-redux";
import SessionHistory from "./Components/BookaSession.WebAppMate/SessionHistory";
import { ganrateToken, isIOS, messaging } from "./firebase";
import { makePostRequest } from "./services/api";
import { URLS } from "./constants";
import { incrementByAmount } from "./store/reducers/cartReducer";
import RePayment from "./Components/PurchaseResources.WebAppMate/RePayment";
import Ticketpage from "./Components/HelpCenter.WebAppmate/Ticketlistpage";
import Ticketdetailpage from "./Components/HelpCenter.WebAppmate/Ticketdetailpage";
import ChangePassword from "./Components/Registration.WebAppMate/Chnagepassword";
import Childprofileupdateview1 from "./Components/ViewProfile.WebAppMate/Childprofileupdateview1"
import SubScriptionView from "./Components/Subscription.WebAppMate/SubScriptionView";
import { getChatCount, getNotification } from "./store/reducers/userReducer";
import { onMessage } from "firebase/messaging";
import notificationSound from '../src/assets/sound/level-up-191997.mp3';

function App() {
  const userStore = useSelector((store) => store.user);
  let userData = decodeToken();
  const dispatch = useDispatch()
  const firstName = localStorage.getItem("fristname");
  const [path, setPath] = useState(window.location.pathname);
  const [isMounted, setIsMounted] = useState(false);
  const [unreadnotification, setunreadnotification] = useState(0);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  // handle local store value based on condition
  useEffect(() => {
    if (isMounted) {
      requestPermissionForFcmToken();
      if (localStorage.getItem("cart") && localStorage.getItem("signupcard")) {
        dispatch(incrementByAmount(
          JSON.parse(localStorage.getItem("cart")).scheduleData.length +
          JSON.parse(localStorage.getItem("signupcard")).scheduleData.length
        ));
      } else if (localStorage.getItem("cart")) {
        dispatch(incrementByAmount(JSON.parse(localStorage.getItem("cart")).scheduleData.length));
      } else if (localStorage.getItem("signupcard")) {
        dispatch(incrementByAmount(JSON.parse(localStorage.getItem("signupcard")).scheduleData.length));
      }
      if (window.location.pathname.includes("rePayment")) {
        const path = window.location.pathname;
        const data = {
          mentoring_id: path.split("/")[2],
          payment_id: path.split("/")[3]?.split("_")[0]
        };
        localStorage.setItem("mailUrl", JSON.stringify(data));
      }
    }
    // eslint-disable-next-line
  }, [isMounted]);

  const requestPermissionForFcmToken = async () => {
    if (isIOS()) return;
    const currentToken = await ganrateToken();
    if (currentToken && userData) {
      sendTokenToServer(currentToken);
    }
  };

  // manage notification
  messaging().then((messagingInstance) => {
    onMessage(messagingInstance, (payload) => {
      console.log("notification payload", payload)
      if (payload?.notification?.title?.includes("New Chat Message")) {
        dispatch(getChatCount(parseInt(userStore?.chatCount) + 1))
        if (userStore?.chatSound === false && userData?.userAuth?.chat_sound) {
          console.log("no sound")
        }
        else if (userData?.userAuth?.chat_sound || userStore?.chatSound) {
          const audio = new Audio(notificationSound);
          audio.play().catch(error => {
            console.error('Failed to play sound:', error.message);
          });
        }
      }
      dispatch(getNotification({
        ...userStore?.notification,
        unreadCount: unreadnotification + 1
      }))
      setunreadnotification(prev => prev + 1)
    });
  }).catch((err) => {
    console.error('Failed to initialize messaging instance:', err);
  });

  useEffect(() => {
    setunreadnotification(parseInt(userStore?.notification?.unreadCount));
  }, [userStore?.notification])

  // send fcm token
  const sendTokenToServer = (fcmToken) => {
    const data = {
      "fcm_token": fcmToken,
      "is_web_token": "yes"
    };
    makePostRequest(URLS.updateFcmToken, null, data, null)
      .then((res) => {
        if (res !== undefined && res.code === 200) {
          localStorage.setItem("fcm_token", res.data.result.fcm_token);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  let activeuser = localStorage.getItem('useractive');


  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
  }, [setPath]);

 
  if ((activeuser && userStore.SelectFrist === false) && (path === "/sign-in" || path === "/sign-up" || path === "/forget-password")) {
    return <Navigate to="/" />;
  }

 

  console.warn = () => { };
  console.error = () => { };
  // console.clear();

  return (
    <>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        theme="light"
        autoClose={5000}
      />
      {userData || userStore.SelectFrist ? <Header1 /> : <Header />}
      <div className={`${userData ? "container" : ""} `}>
        <div className={`${userData ? "row" : "row justify-content-center"}  `}>
          <div className={`${userData ? "col-lg-2 marginboottom" : ""}  `}>
            {(firstName || userData?.userAuth?.firstName) && <Sidebar />}
          </div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sign-up" element={<Signup />} />
            <Route path="/sign-up/friend/:id" element={<Signup />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/delete-account" element={<DeleteAccount />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/purchase-page" element={<SubscriptionBuy />} />
            <Route path="/success" element={<SubscriptionVerify />} />
            <Route path="/rePayment/:id/:id" element={<RePayment />} />
            <Route
              path="/parent-registration-form"
              element={<Parentregistrationform />}
            />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/child-info/:id" element={<Childinfo />} />
            <Route
              path="/serve"
              element={
                <ProtectedRoute>
                  <Serve />
                </ProtectedRoute>
              }
            />
            <Route path="/Notification/:id" element={<ProtectedRoute><Notification /></ProtectedRoute>} />
            <Route
              path="/serve/:id"
              element={
                <ProtectedRoute>
                  <Serve />
                </ProtectedRoute>
              }
            />
            <Route
              path="/search-page"
              element={
                <ProtectedRoute>
                  <Searchpage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/full-description/:id"
              element={
                <ProtectedRoute>
                  <FullDescription />
                </ProtectedRoute>
              }
            />
            <Route
              path="/full-description/:id/:savedresources"
              element={
                <ProtectedRoute>
                  <FullDescription />
                </ProtectedRoute>
              }
            />
            <Route
              path="/save-page"
              element={
                <ProtectedRoute>
                  <SavePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/save-page/:id"
              element={
                <ProtectedRoute>
                  <SavePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/share-inspiration"
              element={
                <ProtectedRoute>
                  <Shareinspiration />
                </ProtectedRoute>
              }
            />
            <Route path="/my-subscriptions" element={<MySubscription />} />
            <Route
              path="/purchase-resource/:id"
              element={
                <ProtectedRoute>
                  <PurchaseResource />
                </ProtectedRoute>
              }
            />
            <Route
              path="/purchase-pages-friend"
              element={
                <ProtectedRoute>
                  <PurchasePagesfriend />
                </ProtectedRoute>
              }
            />
{/* setting up route for link copy */}
            <Route
              path="/purchase-pages-friend/:id"
              element={
                <ProtectedRoute>
                  <PurchasePagesfriend />
                </ProtectedRoute>
              }
            />

            <Route
              path="/user-profile/:id"
              element={
                <ProtectedRoute>
                  <UserProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/purchase-page-card/:type"
              element={
                <ProtectedRoute>
                  <Purchasepagecard />
                </ProtectedRoute>

              }
            />
            <Route
              path="/purchase-page3"
              element={
                <ProtectedRoute>
                  <SubscriptionVerify />
                </ProtectedRoute>
              }
            />
            <Route path="/session-history" element={<ProtectedRoute><SessionHistory /></ProtectedRoute>} />
            <Route
              path="/booka-session-manualpicking/:id/:type"
              element={<ProtectedRoute><BookASessionManualpicking /></ProtectedRoute>}
            />
            <Route
              path="/server-payment-history"
              element={
                <ProtectedRoute>
                  <ServerPaymentHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/server-payment-history/:childid"
              element={
                <ProtectedRoute>
                  <ServerPaymentHistory />
                </ProtectedRoute>
              }
            />
            <Route path="/share-post-popup" element={<SharePostpopup />} />
            <Route
              path="/serve-your-resources"
              element={
                <ProtectedRoute>
                  <ServeYourresources />
                </ProtectedRoute>
              }
            />
            <Route
              path="/report-popup"
              element={
                <ProtectedRoute>
                  <Reportpopup />
                </ProtectedRoute>
              }
            />
            <Route
              path="/permisson-share-popup"
              element={<Permissonsharepopup />}
            />
            <Route
              path="/invite-friend-popup"
              element={<InviteFriendpopup />}
            />
            <Route
              path="/child-profile-update-view"
              element={
                <ProtectedRoute>
                  <Childprofileupdateview />
                </ProtectedRoute>
              }
            />
            <Route
              path="/child-profile-update-view/:id"
              element={
                <ProtectedRoute>
                  <Childprofileupdateview1 />
                </ProtectedRoute>
              }
            />
            <Route
              path="/child-profile-page/:id"
              element={
                <ProtectedRoute>
                  <Childprofileupdateview />
                </ProtectedRoute>
              }
            />
            <Route
              path="/serve-dashboard"
              element={
                <ProtectedRoute>
                  <ServeDashboard />
                </ProtectedRoute>
              }
            />
            <Route path="/password/:id" element={<Updatepass />} />
            <Route path="/forget-password" element={<Forgetpassword />} />
            <Route
              path="/save-resources-list"
              element={
                <ProtectedRoute>
                  <SaveResourceList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/subscription"
              element={
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              }
            />
            <Route exact path="/notification" element={<Notification />} />
            <Route
              path="/notification-settings"
              element={<NotificationSettings />}
            />
            <Route path="*" element={<PageNotFound />} />
            <Route
              path="/create-new-group"
              element={
                <ProtectedRoute>
                  <Creategroup />
                </ProtectedRoute>
              }
            />
            <Route
              path="/yourgroup"
              element={
                <ProtectedRoute>
                  <Yourgroup />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-new-group/:id"
              element={
                <ProtectedRoute>
                  <Creategroup />
                </ProtectedRoute>
              }
            />
            <Route
              path="/group-members/:id"
              element={
                <ProtectedRoute>
                  <Yourgroups4 />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-schedule"
              element={
                <ProtectedRoute>
                  <Createschedule />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sale-history"
              element={
                <ProtectedRoute>
                  <SaleHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/groupjoin/:id"
              element={
                <ProtectedRoute>
                  <GroupYouhaveJoin />
                </ProtectedRoute>
              }
            />
            <Route
              path="/groupjoin/:id/:groupname"
              element={
                <ProtectedRoute>
                  <GroupYouhaveJoin />
                </ProtectedRoute>
              }
            />
            <Route path="/view-subscription" element={<SubScriptionView />} />
            <Route path="/ticket-detail-page" element={<ProtectedRoute><Ticketdetailpage /></ProtectedRoute>} />
            <Route path="/ticket-list" element={<ProtectedRoute><Ticketpage /></ProtectedRoute>} />
          </Routes>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
